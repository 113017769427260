import { useLocation } from "react-router-dom";
import { PaginationMetadata } from "../../types/pagination.types";
import { useTranslation } from "react-i18next";

interface PaginationProps {
  paginationMetadata: PaginationMetadata;
  queryParams: URLSearchParams;
}

export default function Pagination({
  paginationMetadata,
  queryParams,
}: PaginationProps) {
  const location = useLocation();
  let size = paginationMetadata.pageSize;
  let offset = parseInt(queryParams.get("offset") || "0", 10);
  const { t } = useTranslation();

  const isLast =
    paginationMetadata.offset + paginationMetadata.pageSize >=
    paginationMetadata.totalCount;
  const isFirst = paginationMetadata.offset === 0;

  const getNextURL = (params: URLSearchParams) => {
    const prevVal = params.get("offset");

    queryParams.set("offset", (offset + size).toString());
    const nextURL = location.pathname + `?${queryParams.toString()}`;
    if (prevVal !== null && !Number.isNaN(prevVal)) {
      queryParams.set("offset", prevVal);
    }
    return nextURL;
  };

  const getPrevURL = (params: URLSearchParams) => {
    const prevVal = params.get("offset");
    queryParams.set("offset", (offset - size).toString());
    const prevURL = location.pathname + `?${queryParams.toString()}`;
    if (prevVal) {
      queryParams.set("offset", prevVal);
    }
    return prevURL;
  };

  return (
    <footer className="flex items-center justify-center space-x-4 py-6 mx-4">
      <a href={getPrevURL(queryParams)} hidden={isLast && isFirst}>
        <button
          disabled={isFirst}
          className="px-4 py-2 text-sm rounded-md text-white bg-cyan-700 hover:bg-cyan-900 disabled:bg-gray-200 disabled:text-gray-500"
          type="button"
        >
          {"\u2190"} {t("pagination.prev")}
        </button>
      </a>
      <a href={getNextURL(queryParams)} hidden={isLast && isFirst}>
        <button
          disabled={isLast}
          className="px-4 py-2 text-sm rounded-md text-white bg-cyan-700 hover:bg-cyan-900 disabled:bg-gray-200 disabled:text-gray-500"
          type="button"
        >
          {t("pagination.next")} {"\u2192"}
        </button>
      </a>
    </footer>
  );
}
