import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Input } from "../ui/input";
import { FormEvent, useState } from "react";

import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLoginErrorAlert as LoginErrorAlert } from "../ErrorHandler/ErrorHandler";
import { ApiError } from "src/types/errors.types";
import { ApiClient } from "src/types/ApiClient";

export default function TokenCardPage({
  apiClient,
  email,
}: {
  apiClient: ApiClient;
  email: string;
}) {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleVerification = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { credential, error } = await apiClient.verify(email, token);
    if (error || !credential) {
      setApiError(error)
      setLoading(false);
      return;
    }

    signIn({
      auth: {
        token: credential.token,
        type: credential.authorizationSchema,
      },
      userState: {
        email: credential.referer,
      },
      refresh: credential.refreshToken,
    })
      ? navigate("/explore")
      : navigate("/login");
      setLoading(false);
  };
  return (
    <form>
      <Card className="max-w-sm">
        <CardHeader>
          <CardTitle>{t("tokenCard.title")}</CardTitle>
          <CardDescription>{t("tokenCard.desc")}</CardDescription>
          {apiError && <LoginErrorAlert apiError={apiError!} />}
        </CardHeader>
        <CardContent>
          <div className="flex space-x-4">
            <Input
              className="w-full flex-1"
              inputMode="numeric"
              placeholder="******"
              type="password"
              value={token}
              onChange={(e) => {
                setApiError(undefined);
                setToken(e.target.value);
              }
              }
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button type="submit" onClick={handleVerification} disabled={loading}>
            {loading && 
              <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
            }
            {t("tokenCard.submit")}
          </Button>
          <Button variant="outline">{t("tokenCard.resend")}</Button>
        </CardFooter>
      </Card>
    </form>
  );
}
