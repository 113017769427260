import { ReactElement } from "react";
import ErrorPage from "./ErrorComponents/ErrorPage/ErrorPage";
import { TIENDANUBE_AUTH_LINK } from "src/constants";
import ErrorAlert from "./ErrorComponents/ErrorAlert";
import { ApiError } from "src/types/errors.types";

const getBundleErrorPage = ({
  apiError,
}: {
  apiError: ApiError;
}): ReactElement => {
  if (apiError.detail === "STORE_NEEDS_RE_AUTH") {
    return (
      <ErrorPage
      message={"error.reAuthErrorMessage"}
      handler={async () => {
        window.location.href = TIENDANUBE_AUTH_LINK;
      }}
      buttonMessage={"error.reAuthButtonMessage"}
      />
    );
  }
  return <ErrorPage />;
};

export { getBundleErrorPage };

const getLoginErrorAlert = ({
  apiError,
}: {
  apiError: ApiError;
}): ReactElement => {
  if (apiError.detail === "NO_STORE_FOR_EMAIL") {
    return <ErrorAlert message={"error.loginErrorMessage"} />;
  }
  if (apiError.detail === "NO_CREDENTIALS_FOUND") {
      return <ErrorAlert message={"error.verifyErrorMessage"} />;
  }
  return <ErrorAlert message={"error.message"} />;
};

export { getLoginErrorAlert };

