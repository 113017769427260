import { Download, X } from "lucide-react";
import { Button } from "../../ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose
} from "../../ui/dialog";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { useState } from "react";


export function QrModal({ qrUrl, bundleName }: { qrUrl: string; bundleName: string; }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const downloadImage = async () => {
    const response =  await fetch(qrUrl);
    const blob = await response.blob();
    saveAs(blob, `${bundleName}.png`)
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{`${t("qrModal.title")}: ${bundleName}`}</DialogTitle>
      </DialogHeader>
      <div className="flex mx-auto" style={{display: loading ? "block" : "none"}}>
        <div className="animate-spin h-12 w-12 rounded-full border-t-4 border-gray-900" />
      </div>
      <div style={{display: loading ? "none" : "block"}}>
        <img src={qrUrl} alt="QR Code" className="flex mx-auto mb-2" onLoad={() => setLoading(false)}/>
      </div>
      <DialogFooter className="flex mx-auto">
        <Button
          type="button"
          variant="secondary"
          className="flex mx-auto"
          onClick={downloadImage}
        >
          {t("qrModal.download")}
          <Download className="pl-2" />
        </Button>
      </DialogFooter>
      <DialogClose className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </DialogClose>
    </DialogContent>
  );
}
