import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ErrorAlert({
  message
}: {
  message?: string;
}) {
  const { t } = useTranslation();

  const displayMessage = message ? t(message) : t("error.message");
  return (
    <Alert severity="error">{displayMessage}</Alert>
  );
}
