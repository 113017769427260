import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen flex flex-col">
      <NavBar />
      <div className="bg-gray-100 flex items-center justify-center">
      <div className="rounded-lg shadow-lg bg-white p-6 space-y-6 border border-gray-200 dark:border-gray-700">
      <div className="mt-200">
        <h1 className="text-center text-3xl font-bold">{t(`terms.title`)}</h1>
        <h2 className="text-xl font-bold">{t(`terms.introTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.intro`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.acceptTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.accept`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.registerTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.register`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.platformTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.platform`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.obligationsTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.obligations`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.responsibilityTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.responsibility`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.intellectualPropertyTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.intellectualProperty`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.linksTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.links`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.changesTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.changes`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.finishTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.finish`)}
        </p>
        <h2 className="text-xl font-bold">{t(`terms.lawTitle`)}</h2>
        <p className="text-zinc-500 dark:text-zinc-400">
          {t(`terms.law`)}
        </p>
      </div>
    </div>
      </div>
    </div>
  );
};

export default Terms;
