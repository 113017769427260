import { Link } from "react-router-dom";
import "./ErrorPage.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../ui/button";

export default function ErrorPage({
  message,
  buttonMessage,
  handler,
}: {
  message?: string;
  buttonMessage?: string;
  handler?: () => void;
}) {
  /**
   * Message and ButtonMessage should be the key values for the i18n translation table so for example:
   * <ErrorPage message={"error.message"} buttonMessage={"error.button"} />
   * both strings will be use as input for useTranslation
   */
  const { t } = useTranslation();

  const displayMessage = message ? t(message) : t("error.message");
  const buttonDisplayMessage = buttonMessage
    ? t(buttonMessage)
    : t("error.button");
  return (
    <div id="error-page" className="errorPage">
      <h1>{t("error.oops")}</h1>
      <p>{displayMessage}</p>
      {handler ? (
        <Button className="mt-2" variant="outline" onClick={handler}>
          {buttonDisplayMessage}
        </Button>
      ) : (
        <Link to={"/"}>
          <Button className="mt-2" variant="outline" onClick={handler}>
            {buttonDisplayMessage}
          </Button>
        </Link>
      )}
    </div>
  );
}
