import { CredentialsResponse, LoginResponse } from "src/types/auth.types";
import { ApiClient } from "../types/ApiClient";
import { BundleCreationDTO, BundleDTO, BundleResponse, BundleUpdateDTO, BundlesResponse, DeleteBundleResponse } from "src/types/bundle.types";
import { CheckoutResponse, ProductsResponse, StoreResponse, VariantDTO } from "src/types/store.types";

const BASE_URL = `${process.env.REACT_APP_BASE_URL ||"https://yass-bundle.onrender.com"}/api/v2`;

export class ApiClientImpl implements ApiClient {

  public async login(email: string): Promise<LoginResponse> {
    const url = `${BASE_URL}/auth/login`;
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    };
    const result = await fetch(url, requestOptions);

    return {
      error: result.ok ? null : await result.json(),
    };
  }

  public async verify(email: string, token: string): Promise<CredentialsResponse> {
    const url = `${BASE_URL}/auth/verify`;
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        email: email,
        code: token,
      }),
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      credential: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async renovate(token: string): Promise<CredentialsResponse> {
    const url = `${BASE_URL}/auth/renovate`;
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      credential: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async createBundle(bundle: BundleCreationDTO, token: string): Promise<BundleResponse> {
    const url = `${BASE_URL}/bundle/`;
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(bundle),
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      bundle: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async updateBundle(slug: string, bundle: BundleUpdateDTO, token: string): Promise<BundleResponse> {
    const url = `${BASE_URL}/bundle/${slug}`;
    const requestOptions: RequestInit = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(bundle),
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      bundle: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async deleteBundle(slug: string, token: string): Promise<DeleteBundleResponse> {
    const url = `${BASE_URL}/bundle/${slug}`;
    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const result = await fetch(url, requestOptions);

    return {
      error: result.ok ? null : await result.json(),
    };
  }

  public async getBundle(slug: string, token: string): Promise<BundleResponse> {
    const url = `${BASE_URL}/bundle/${slug}`;
    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      bundle: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async getPublicBundleContent(slug: string): Promise<BundleResponse> {
    const url = `${BASE_URL}/public/bundle/${slug}/content/`;
    const result = await fetch(url);
    
    const resultJson = await result.json();

    return {
      bundle: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async getBundles(size: number, offset: number, token: string): Promise<BundlesResponse> {
    const url = `${BASE_URL}/bundle/?size=${size}&offset=${offset}`;

    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      bundles: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public getBundleQR(bundle: BundleDTO): string {
    return `${BASE_URL}/bundle/${bundle.slug}/content/qr`;
  }

  public async getStore(token: string): Promise<StoreResponse> {
    const url = `${BASE_URL}/store/`;
    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      store: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async getPublicStore(storeId: string): Promise<StoreResponse> {
    const url = `${BASE_URL}/store/${storeId}`;
    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      store: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }

  public async getStoreProducts(storeId: string, token: string): Promise<ProductsResponse> {
    const url = `${BASE_URL}/store/${storeId}/products`;
    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      products: result.ok ? resultJson : [],
      error: result.ok ? null : resultJson,
    };
  }

  public async getPublicStoreProduct(storeId: string, productId: string): Promise<ProductsResponse> {
    const url = `${BASE_URL}/store/${storeId}/products/${productId}`;
    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      products: result.ok ? resultJson : [],
      error: result.ok ? null : resultJson,
    };
  }

  public async createOrder(
    storeId: string,
    slug: string,
    name: string,
    surname: string,
    email: string,
    variants: VariantDTO[]
  ): Promise<CheckoutResponse> {
    const url = `${BASE_URL}/store/${storeId}/bundle/${slug}/order`;

    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({customerEmail: email, customerName: name, customerSurname: surname, variants: variants})
    };
    const result = await fetch(url, requestOptions);
    const resultJson = await result.json();

    return {
      checkout: result.ok ? resultJson : null,
      error: result.ok ? null : resultJson,
    };
  }
}
