import { useTranslation } from "react-i18next";

export default function Loader() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="animate-spin h-12 w-12 rounded-full border-t-4 border-gray-900" />
      <p className="mt-4 text-lg font-semibold text-gray-900">{t(`loading`)}</p>
    </div>
  );
}
