
import NavBar from "../NavBar";
import { useTranslation } from "react-i18next";
import { useForm, ValidationError } from "@formspree/react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Instagram, Mail } from "lucide-react";
export default function ContactPage() {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm("xanwybve");

  return (
    <div className="bg-white">
      <NavBar />
      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
              {t("contact.title")}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t("contact.description")}
            </p>
            <div className="mt-10 flex pb-4 items-center justify-center gap-x-6 lg:justify-start">
            {(state.succeeded) && (<p className="mt-6 text-lg leading-8 text-gray-600">
              {t("contact.congrats")}
            </p>)}
              {(!state.succeeded) && (<form onSubmit={handleSubmit} className="w-full flex items-center justify-center flex-col ml-1/3 mr-1/3">
                <Label htmlFor="name" className="mb-2">
                  {t("contact.name")}
                </Label>
                <Input
                  id="name"
                  type="text" 
                  required
                  name="name"
                />
                <Label htmlFor="store" className="mb-2 mt-2">
                  {t("contact.store")}
                </Label>
                <Input
                  id="store"
                  type="text" 
                  required
                  name="store"
                />
                <Label htmlFor="email" className="mb-2 mt-2">
                  {t("contact.email")}
                </Label>
                <Input
                  id="email"
                  type="email" 
                  required
                  placeholder="m@example.com"
                  name="email"
                  className="mb-4"
                />
                <ValidationError 
                  prefix="Email" 
                  field="email"
                  errors={state.errors}
                />
                <textarea
                  className="mb-4 w-full h-40 border-black"
                  id="message"
                  name="message"
                  required
                  placeholder={t("contact.message")}
                />
                <ValidationError 
                  prefix="Message" 
                  field="message"
                  errors={state.errors}
                />
                <Button type="submit" disabled={state.submitting}>
                {t("contact.submit")}
                </Button>
                <div className="mt-6 flex-col items-center justify-center">
                  <div className="flex flex-row items-center mb-4">
                    <a href="https://u42357640.ct.sendgrid.net/ls/click?upn=u001.Bfv862uL1Akct-2FJRPvHpCz6MlJLSrxmE1sY951IsAZxo9ZUirDwhFXO5x6eXR1DsZfo8NPCmL-2FTcF-2B2zoTdAee3eYVeh07NUC8aMjLuDASNNX4NybIu7l46xVdxjqFvWh6L-2BRlUOK8C-2Fx8mpIf30uA-3D-3Duu9J_aPEHzqD8qr1-2B50upcDvN80MtpMUUPTKnfSKN3rd6WwazWl3kkL3gCTPO9BEc8cJrWHnFsxY2KN7T-2FzVhwEIdmUheE0olVdgjOTco2-2B1oaqZ5Jfqizg0Tr85x2K9MK3yISoRTnlbtzubCOM0VjNyiQdZXndxz2QRtzz8wSXYtGzjgR1a1vs1XsDHdHbncStMcvwW9OtapaaVtCXaXmjk0wko63N1I-2B8dyOhxoSN7SJILYTwOC92ywhAFDb7c6gGcxZxfrnvSg7eCgvXe64eClOw93jQ-2BqBh3HnLQItzfnAjme-2FqRXo3feApNvPF8A1Os842bt0YelFFRONKFCLpLVXA-3D-3D"className="ml-1 text-lg leading-8 text-blue-500 hover:text-blue-700" target="_blank" rel="noopener noreferrer">
                    {t("contact.faqs")}
                    </a>
                  </div>
                  <div className="flex flex-row items-center">
                    <Mail />
                    <a href="mailto:contact@yass.tech"className="ml-1 text-lg leading-8 text-gray-600">
                    contact@yass.tech
                    </a>
                  </div>
                  <div className="flex flex-row items-center mt-6">
                    <Instagram />
                    <a href="https://www.instagram.com/yass.technologies"className="ml-1 text-lg leading-8 text-gray-600">
                      YASS Technologies
                    </a>
                  </div>
                </div>
              </form>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
